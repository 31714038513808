@keyframes lazyload {
  0% {
    transform: rotate(0deg);
  }
  70%, 100% {
    transform: rotate(360deg);
  }
}

@keyframes scaleUpX {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes animation-tada {
  0% {
    transform: scale(1);
  }
  10%, 20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

/* https://google-webfonts-helper.herokuapp.com */
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/roboto-v20-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-latin-300.woff2") format("woff2"), url("/assets/fonts/roboto-v20-latin-300.woff") format("woff"), url("/assets/fonts/roboto-v20-latin-300.ttf") format("truetype"), url("/assets/fonts/roboto-v20-latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/roboto-v20-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/roboto-v20-latin-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-latin-regular.woff2") format("woff2"), url("/assets/fonts/roboto-v20-latin-regular.woff") format("woff"), url("/assets/fonts/roboto-v20-latin-regular.ttf") format("truetype"), url("/assets/fonts/roboto-v20-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/roboto-v20-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Italic"), local("Roboto-Italic"), url("/assets/fonts/roboto-v20-latin-italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-latin-italic.woff2") format("woff2"), url("/assets/fonts/roboto-v20-latin-italic.woff") format("woff"), url("/assets/fonts/roboto-v20-latin-italic.ttf") format("truetype"), url("/assets/fonts/roboto-v20-latin-italic.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/roboto-v20-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("/assets/fonts/roboto-v20-latin-700.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-latin-700.woff2") format("woff2"), url("/assets/fonts/roboto-v20-latin-700.woff") format("woff"), url("/assets/fonts/roboto-v20-latin-700.ttf") format("truetype"), url("/assets/fonts/roboto-v20-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-mono-300 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/roboto-mono-v7-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Mono Light"), local("RobotoMono-Light"), url("/assets/fonts/roboto-mono-v7-latin-300.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-mono-v7-latin-300.woff2") format("woff2"), url("/assets/fonts/roboto-mono-v7-latin-300.woff") format("woff"), url("/assets/fonts/roboto-mono-v7-latin-300.ttf") format("truetype"), url("/assets/fonts/roboto-mono-v7-latin-300.svg#RobotoMono") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-mono-regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/roboto-mono-v7-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Mono"), local("RobotoMono-Regular"), url("/assets/fonts/roboto-mono-v7-latin-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-mono-v7-latin-regular.woff2") format("woff2"), url("/assets/fonts/roboto-mono-v7-latin-regular.woff") format("woff"), url("/assets/fonts/roboto-mono-v7-latin-regular.ttf") format("truetype"), url("/assets/fonts/roboto-mono-v7-latin-regular.svg#RobotoMono") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-mono-500 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/roboto-mono-v7-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Mono Medium"), local("RobotoMono-Medium"), url("/assets/fonts/roboto-mono-v7-latin-500.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-mono-v7-latin-500.woff2") format("woff2"), url("/assets/fonts/roboto-mono-v7-latin-500.woff") format("woff"), url("/assets/fonts/roboto-mono-v7-latin-500.ttf") format("truetype"), url("/assets/fonts/roboto-mono-v7-latin-500.svg#RobotoMono") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-mono-700 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/roboto-mono-v7-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Mono Bold"), local("RobotoMono-Bold"), url("/assets/fonts/roboto-mono-v7-latin-700.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-mono-v7-latin-700.woff2") format("woff2"), url("/assets/fonts/roboto-mono-v7-latin-700.woff") format("woff"), url("/assets/fonts/roboto-mono-v7-latin-700.ttf") format("truetype"), url("/assets/fonts/roboto-mono-v7-latin-700.svg#RobotoMono") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

body {
  font-family: "Roboto", sans-serif;
}

a {
  color: inherit;
}

dl dt {
  font-weight: 700;
}

strong, b {
  font-weight: 700;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

@font-face {
  font-family: 'bnw-icons';
  src: url("/assets/fonts/bnw-icons.ttf?o6c6lu") format("truetype"), url("/assets/fonts/bnw-icons.woff?o6c6lu") format("woff"), url("/assets/fonts/bnw-icons.svg?o6c6lu#bnw-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"]::before, [class*=" icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bnw-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="iconafter-"]::after, [class*=" iconafter-"]::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bnw-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-print:before, .iconafter-print::after {
  content: "\44";
}

.icon-glossary:before {
  content: "\3f";
}

.icon-zoom:before {
  content: "\2b";
}

.icon-fullscreen:before {
  content: "\46";
}

.icon-play:before {
  content: "\70";
}

.icon-architect_profile:before, .iconafter-architect_profile::after {
  content: "\e901";
}

.icon-arrow_down:before {
  content: "\76";
}

.icon-arrow_left:before {
  content: "\3c";
}

.icon-arrow_right:before, .iconafter-arrow_right:after {
  content: "\3e";
}

.icon-arrow2_down:before {
  content: "\2193";
}

.icon-arrow2_right:before, .iconafter-arrow2_right:after {
  content: "\2192";
}

.icon-bookmark:before, .iconafter-bookmark::after {
  content: "\42";
}

.icon-close:before, .iconafter-close::after {
  content: "\58";
}

.icon-cookie_arrow_down:before {
  content: "\e909";
}

.icon-cookie_arrow_right:before {
  content: "\e90a";
}

.icon-map_location:before {
  content: "\e90b";
}

.icon-search:before {
  content: "\73";
}

.icon-social_clipboard:before {
  content: "\63";
}

.icon-social_facebook:before {
  content: "\66";
}

.icon-social_mail:before {
  content: "\6d";
}

.icon-social_twitter:before {
  content: "\74";
}

.icon-social_linkedin:before {
  content: "\eaca";
}

html {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  background-color: #000;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

template {
  display: none;
}

.visibility-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.meta-ajax {
  display: none;
}

.button {
  box-shadow: 0 0 0 0 transparent;
  border: 0 none transparent;
  outline: none;
  margin-top: 50px;
  margin-bottom: 15px;
  width: 130px;
  background-color: #000;
  color: #FFF;
  padding: 9px 12px 9px;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  font-family: "Roboto Mono", monospace;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03125em;
  cursor: pointer;
}

.button:focus {
  outline: 1px solid #91BE4E;
}

.button.invert {
  background-color: #FFF;
  color: #000;
}

.screenreader {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.jsPageDot {
  position: fixed;
  top: 100vh;
  left: 100vw;
  z-index: 1;
  pointer-events: none;
  opacity: 0.1;
}

@media screen and (max-width: 1199px) {
  html, body {
    height: 100%;
  }
  .button {
    padding: 10px 18px;
  }
  .hide-for-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  body {
    background-color: #000;
  }
  .show-for-mobile {
    display: none !important;
  }
  .hide-for-mobile {
    display: inherit;
  }
}

@media not print {
  .print-only {
    display: none !important;
  }
}

@media print {
  .print-only {
    display: block;
  }
}

.row {
  line-height: 0;
  font-size: 0;
  /*
		& + .row {
			margin-top: $gap-size;
		}
		*/
}

.row:after {
  content: " ";
  display: table;
  clear: both;
}

.row .row {
  margin-left: -20px;
  margin-right: -20px;
}

.row .columns {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  line-height: 1rem;
  font-size: 1rem;
  padding-left: 20px;
  padding-right: 20px;
}

.row .columns img {
  max-width: 100%;
  height: auto;
}

.row .columns.align-bottom {
  vertical-align: bottom;
}

.row.collapse .columns {
  padding-left: 0;
  padding-right: 0;
}

.row.collapse .row {
  margin-left: 0;
  margin-right: 0;
}

.row.expand {
  margin-left: -20px;
  margin-right: -20px;
}

.row.small-gaps {
  margin-left: -6px;
  margin-right: -6px;
}

.row.small-gaps .columns {
  padding-left: 6px;
  padding-right: 6px;
}

/* use media queries for fluid layout sizing */
.columns.small-1 {
  width: 8.33333%;
  width: calc(100% / 12);
}

.columns.small-push-1 {
  left: 8.33333%;
  position: relative;
}

.columns.small-pull-1 {
  left: -8.33333%;
  position: relative;
}

.columns.small-2 {
  width: 16.66667%;
  width: calc(100% / 6);
}

.columns.small-push-2 {
  left: 16.66667%;
  position: relative;
}

.columns.small-pull-2 {
  left: -16.66667%;
  position: relative;
}

.columns.small-3 {
  width: 25%;
  width: calc(100% / 4);
}

.columns.small-push-3 {
  left: 25%;
  position: relative;
}

.columns.small-pull-3 {
  left: -25%;
  position: relative;
}

.columns.small-4 {
  width: 33.33333%;
  width: calc(100% / 3);
}

.columns.small-push-4 {
  left: 33.33333%;
  position: relative;
}

.columns.small-pull-4 {
  left: -33.33333%;
  position: relative;
}

.columns.small-5 {
  width: 41.66667%;
  width: calc(100% / 2.4);
}

.columns.small-push-5 {
  left: 41.66667%;
  position: relative;
}

.columns.small-pull-5 {
  left: -41.66667%;
  position: relative;
}

.columns.small-6 {
  width: 50%;
  width: calc(100% / 2);
}

.columns.small-push-6 {
  left: 50%;
  position: relative;
}

.columns.small-pull-6 {
  left: -50%;
  position: relative;
}

.columns.small-7 {
  width: 58.33333%;
  width: calc(100% / 1.71429);
}

.columns.small-push-7 {
  left: 58.33333%;
  position: relative;
}

.columns.small-pull-7 {
  left: -58.33333%;
  position: relative;
}

.columns.small-8 {
  width: 66.66667%;
  width: calc(100% / 1.5);
}

.columns.small-push-8 {
  left: 66.66667%;
  position: relative;
}

.columns.small-pull-8 {
  left: -66.66667%;
  position: relative;
}

.columns.small-9 {
  width: 75%;
  width: calc(100% / 1.33333);
}

.columns.small-push-9 {
  left: 75%;
  position: relative;
}

.columns.small-pull-9 {
  left: -75%;
  position: relative;
}

.columns.small-10 {
  width: 83.33333%;
  width: calc(100% / 1.2);
}

.columns.small-push-10 {
  left: 83.33333%;
  position: relative;
}

.columns.small-pull-10 {
  left: -83.33333%;
  position: relative;
}

.columns.small-11 {
  width: 91.66667%;
  width: calc(100% / 1.09091);
}

.columns.small-push-11 {
  left: 91.66667%;
  position: relative;
}

.columns.small-pull-11 {
  left: -91.66667%;
  position: relative;
}

.columns.small-12 {
  width: 100%;
  width: calc(100% / 1);
}

.columns.small-push-12 {
  left: 100%;
  position: relative;
}

.columns.small-pull-12 {
  left: -100%;
  position: relative;
}

.row.equalize-small {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.row.equalize-small > .columns {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.row.equalize-small > .columns.equalize-top {
  vertical-align: top;
}

@media (min-width: 720px) {
  .columns.medium-1 {
    width: 8.33333%;
    width: calc(100% / 12);
  }
  .columns.medium-push-1 {
    left: 8.33333%;
    position: relative;
  }
  .columns.medium-pull-1 {
    left: -8.33333%;
    position: relative;
  }
  .columns.medium-2 {
    width: 16.66667%;
    width: calc(100% / 6);
  }
  .columns.medium-push-2 {
    left: 16.66667%;
    position: relative;
  }
  .columns.medium-pull-2 {
    left: -16.66667%;
    position: relative;
  }
  .columns.medium-3 {
    width: 25%;
    width: calc(100% / 4);
  }
  .columns.medium-push-3 {
    left: 25%;
    position: relative;
  }
  .columns.medium-pull-3 {
    left: -25%;
    position: relative;
  }
  .columns.medium-4 {
    width: 33.33333%;
    width: calc(100% / 3);
  }
  .columns.medium-push-4 {
    left: 33.33333%;
    position: relative;
  }
  .columns.medium-pull-4 {
    left: -33.33333%;
    position: relative;
  }
  .columns.medium-5 {
    width: 41.66667%;
    width: calc(100% / 2.4);
  }
  .columns.medium-push-5 {
    left: 41.66667%;
    position: relative;
  }
  .columns.medium-pull-5 {
    left: -41.66667%;
    position: relative;
  }
  .columns.medium-6 {
    width: 50%;
    width: calc(100% / 2);
  }
  .columns.medium-push-6 {
    left: 50%;
    position: relative;
  }
  .columns.medium-pull-6 {
    left: -50%;
    position: relative;
  }
  .columns.medium-7 {
    width: 58.33333%;
    width: calc(100% / 1.71429);
  }
  .columns.medium-push-7 {
    left: 58.33333%;
    position: relative;
  }
  .columns.medium-pull-7 {
    left: -58.33333%;
    position: relative;
  }
  .columns.medium-8 {
    width: 66.66667%;
    width: calc(100% / 1.5);
  }
  .columns.medium-push-8 {
    left: 66.66667%;
    position: relative;
  }
  .columns.medium-pull-8 {
    left: -66.66667%;
    position: relative;
  }
  .columns.medium-9 {
    width: 75%;
    width: calc(100% / 1.33333);
  }
  .columns.medium-push-9 {
    left: 75%;
    position: relative;
  }
  .columns.medium-pull-9 {
    left: -75%;
    position: relative;
  }
  .columns.medium-10 {
    width: 83.33333%;
    width: calc(100% / 1.2);
  }
  .columns.medium-push-10 {
    left: 83.33333%;
    position: relative;
  }
  .columns.medium-pull-10 {
    left: -83.33333%;
    position: relative;
  }
  .columns.medium-11 {
    width: 91.66667%;
    width: calc(100% / 1.09091);
  }
  .columns.medium-push-11 {
    left: 91.66667%;
    position: relative;
  }
  .columns.medium-pull-11 {
    left: -91.66667%;
    position: relative;
  }
  .columns.medium-12 {
    width: 100%;
    width: calc(100% / 1);
  }
  .columns.medium-push-12 {
    left: 100%;
    position: relative;
  }
  .columns.medium-pull-12 {
    left: -100%;
    position: relative;
  }
  .row.equalize-medium {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .row.equalize-medium > .columns {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
  }
  .row.equalize-medium > .columns.equalize-top {
    vertical-align: top;
  }
}

@media (min-width: 1200px) {
  .columns.large-1 {
    width: 8.33333%;
    width: calc(100% / 12);
  }
  .columns.large-push-1 {
    left: 8.33333%;
    position: relative;
  }
  .columns.large-pull-1 {
    left: -8.33333%;
    position: relative;
  }
  .columns.large-2 {
    width: 16.66667%;
    width: calc(100% / 6);
  }
  .columns.large-push-2 {
    left: 16.66667%;
    position: relative;
  }
  .columns.large-pull-2 {
    left: -16.66667%;
    position: relative;
  }
  .columns.large-3 {
    width: 25%;
    width: calc(100% / 4);
  }
  .columns.large-push-3 {
    left: 25%;
    position: relative;
  }
  .columns.large-pull-3 {
    left: -25%;
    position: relative;
  }
  .columns.large-4 {
    width: 33.33333%;
    width: calc(100% / 3);
  }
  .columns.large-push-4 {
    left: 33.33333%;
    position: relative;
  }
  .columns.large-pull-4 {
    left: -33.33333%;
    position: relative;
  }
  .columns.large-5 {
    width: 41.66667%;
    width: calc(100% / 2.4);
  }
  .columns.large-push-5 {
    left: 41.66667%;
    position: relative;
  }
  .columns.large-pull-5 {
    left: -41.66667%;
    position: relative;
  }
  .columns.large-6 {
    width: 50%;
    width: calc(100% / 2);
  }
  .columns.large-push-6 {
    left: 50%;
    position: relative;
  }
  .columns.large-pull-6 {
    left: -50%;
    position: relative;
  }
  .columns.large-7 {
    width: 58.33333%;
    width: calc(100% / 1.71429);
  }
  .columns.large-push-7 {
    left: 58.33333%;
    position: relative;
  }
  .columns.large-pull-7 {
    left: -58.33333%;
    position: relative;
  }
  .columns.large-8 {
    width: 66.66667%;
    width: calc(100% / 1.5);
  }
  .columns.large-push-8 {
    left: 66.66667%;
    position: relative;
  }
  .columns.large-pull-8 {
    left: -66.66667%;
    position: relative;
  }
  .columns.large-9 {
    width: 75%;
    width: calc(100% / 1.33333);
  }
  .columns.large-push-9 {
    left: 75%;
    position: relative;
  }
  .columns.large-pull-9 {
    left: -75%;
    position: relative;
  }
  .columns.large-10 {
    width: 83.33333%;
    width: calc(100% / 1.2);
  }
  .columns.large-push-10 {
    left: 83.33333%;
    position: relative;
  }
  .columns.large-pull-10 {
    left: -83.33333%;
    position: relative;
  }
  .columns.large-11 {
    width: 91.66667%;
    width: calc(100% / 1.09091);
  }
  .columns.large-push-11 {
    left: 91.66667%;
    position: relative;
  }
  .columns.large-pull-11 {
    left: -91.66667%;
    position: relative;
  }
  .columns.large-12 {
    width: 100%;
    width: calc(100% / 1);
  }
  .columns.large-push-12 {
    left: 100%;
    position: relative;
  }
  .columns.large-pull-12 {
    left: -100%;
    position: relative;
  }
  .row.equalize-large {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .row.equalize-large > .columns {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
  }
  .row.equalize-large > .columns.equalize-top {
    vertical-align: top;
  }
}

@media (min-width: 1600px) {
  .columns.xlarge-1 {
    width: 8.33333%;
    width: calc(100% / 12);
  }
  .columns.xlarge-push-1 {
    left: 8.33333%;
    position: relative;
  }
  .columns.xlarge-pull-1 {
    left: -8.33333%;
    position: relative;
  }
  .columns.xlarge-2 {
    width: 16.66667%;
    width: calc(100% / 6);
  }
  .columns.xlarge-push-2 {
    left: 16.66667%;
    position: relative;
  }
  .columns.xlarge-pull-2 {
    left: -16.66667%;
    position: relative;
  }
  .columns.xlarge-3 {
    width: 25%;
    width: calc(100% / 4);
  }
  .columns.xlarge-push-3 {
    left: 25%;
    position: relative;
  }
  .columns.xlarge-pull-3 {
    left: -25%;
    position: relative;
  }
  .columns.xlarge-4 {
    width: 33.33333%;
    width: calc(100% / 3);
  }
  .columns.xlarge-push-4 {
    left: 33.33333%;
    position: relative;
  }
  .columns.xlarge-pull-4 {
    left: -33.33333%;
    position: relative;
  }
  .columns.xlarge-5 {
    width: 41.66667%;
    width: calc(100% / 2.4);
  }
  .columns.xlarge-push-5 {
    left: 41.66667%;
    position: relative;
  }
  .columns.xlarge-pull-5 {
    left: -41.66667%;
    position: relative;
  }
  .columns.xlarge-6 {
    width: 50%;
    width: calc(100% / 2);
  }
  .columns.xlarge-push-6 {
    left: 50%;
    position: relative;
  }
  .columns.xlarge-pull-6 {
    left: -50%;
    position: relative;
  }
  .columns.xlarge-7 {
    width: 58.33333%;
    width: calc(100% / 1.71429);
  }
  .columns.xlarge-push-7 {
    left: 58.33333%;
    position: relative;
  }
  .columns.xlarge-pull-7 {
    left: -58.33333%;
    position: relative;
  }
  .columns.xlarge-8 {
    width: 66.66667%;
    width: calc(100% / 1.5);
  }
  .columns.xlarge-push-8 {
    left: 66.66667%;
    position: relative;
  }
  .columns.xlarge-pull-8 {
    left: -66.66667%;
    position: relative;
  }
  .columns.xlarge-9 {
    width: 75%;
    width: calc(100% / 1.33333);
  }
  .columns.xlarge-push-9 {
    left: 75%;
    position: relative;
  }
  .columns.xlarge-pull-9 {
    left: -75%;
    position: relative;
  }
  .columns.xlarge-10 {
    width: 83.33333%;
    width: calc(100% / 1.2);
  }
  .columns.xlarge-push-10 {
    left: 83.33333%;
    position: relative;
  }
  .columns.xlarge-pull-10 {
    left: -83.33333%;
    position: relative;
  }
  .columns.xlarge-11 {
    width: 91.66667%;
    width: calc(100% / 1.09091);
  }
  .columns.xlarge-push-11 {
    left: 91.66667%;
    position: relative;
  }
  .columns.xlarge-pull-11 {
    left: -91.66667%;
    position: relative;
  }
  .columns.xlarge-12 {
    width: 100%;
    width: calc(100% / 1);
  }
  .columns.xlarge-push-12 {
    left: 100%;
    position: relative;
  }
  .columns.xlarge-pull-12 {
    left: -100%;
    position: relative;
  }
  .row.equalize-xlarge {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .row.equalize-xlarge > .columns {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
  }
  .row.equalize-xlarge > .columns.equalize-top {
    vertical-align: top;
  }
}

[role=main]::before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  background-color: transparent;
  opacity: 0;
  z-index: 9999;
  transition: height 40ms linear 200ms, opacity 200ms linear;
}

[role=main].loading::before {
  height: 100%;
}

@media print {
  [role=main]::before {
    content: none !important;
    position: static !important;
  }
}

@media screen and (max-width: 1199px) {
  [role=main] {
    position: relative;
    height: 100%;
  }
  [role=main].loading {
    width: 100%;
    overflow-x: hidden;
    overflow-y: visible;
  }
}

@media screen and (min-width: 1200px) {
  .page {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    z-index: 200;
    padding: 72px 0 35px;
    background-color: #000;
    color: #FFF;
  }
}

#debug_toggle {
  position: fixed;
  right: 0;
  bottom: 35px;
  background-color: white;
  padding: 8px 15px;
  font-weight: 700;
  z-index: 10001;
}

#debug {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  left: 0;
  top: 0;
  z-index: 10000;
  display: none;
  background-color: white;
  padding: 40px;
}

#debug.active {
  display: block;
}

.teaser {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-bottom: 30px;
}

.teaser .teaser-link {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
}

.teaser .teaser-info {
  overflow-wrap: break-word;
  hyphens: auto;
  word-break: break-word;
}

.teaser .selection {
  font-family: "Roboto Mono", monospace;
}

.teaser .teaser-image figcaption {
  display: none;
}

.teaser .detail-image-wrapper {
  position: relative;
  width: 96px;
  height: 72px;
  background-color: #FFF;
  overflow: hidden;
}

.teaser .detail-image-wrapper a {
  display: block;
  line-height: 0;
  font-size: 0;
}

.teaser .detail-image-wrapper img {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.teaser .detail-image.scale-up-x .detail-image-wrapper img {
  max-width: initial;
  max-height: initial;
  width: 100%;
}

.teaser .detail-image.scale-up-y .detail-image-wrapper img {
  max-width: initial;
  max-height: initial;
  height: 100%;
}

.teaser.teaser-text .teaser-head .head {
  position: relative;
}

.teaser.teaser-text .teaser-new-tag {
  position: absolute;
  display: inline-block;
  bottom: 1px;
  color: #FFF;
  line-height: 1;
  font-size: 11px;
  padding: 1px 5px 3px;
  background-color: #929292;
  margin-left: 10px;
  border-radius: 2px;
}

.teaser.teaser-text .teaser-new-tag::before {
  content: '';
  content: '';
  z-index: 2;
  height: 0;
  width: 0;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #929292;
  border-top: 5px solid transparent;
  position: absolute;
  left: -4px;
  top: 3px;
}

@media screen and (max-width: 1199px) {
  .teaser:not(.teaser-text) .teaser-image {
    float: left;
    display: inline-block;
    margin-right: 14px;
  }
  .teaser:not(.teaser-text) .selection {
    font-size: 11px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.04545em;
    margin-bottom: 3px;
  }
  .teaser:not(.teaser-text) .head {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03571em;
  }
  .teaser:not(.teaser-text) .body {
    display: none;
  }
  .teaser:not(.teaser-text)::after {
    content: '';
    display: table;
    clear: both;
  }
  .teaser:not(.teaser-text) .btn-remove {
    font-size: 11px;
    line-height: 25px;
    position: relative;
    top: 8px;
    padding: 0 5px;
    margin-bottom: 4px;
    display: block;
    clear: both;
    background-color: #313131;
    z-index: 10;
  }
  .teaser:not(.teaser-text) .btn-remove::after {
    float: right;
    line-height: inherit;
    margin-right: 4px;
    vertical-align: middle;
  }
  .teaser-text {
    min-height: 72px;
    padding-left: 106px;
    margin: 0 20px 30px;
    overflow-x: hidden;
  }
  .teaser-text .head {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03571em;
  }
  .teaser-text .body {
    font-size: 11px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.04545em;
    margin-bottom: 3px;
  }
  .teaser-text .teaser-image {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media screen and (min-width: 1200px) {
  .teaser.teaser-tiny .teaser-image {
    float: left;
    display: inline-block;
    margin-right: 14px;
  }
  .teaser.teaser-tiny .selection {
    font-size: 11px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.04545em;
    margin-bottom: 3px;
  }
  .teaser.teaser-tiny .head {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03571em;
  }
  .teaser.teaser-tiny .body {
    display: none;
  }
  .teaser.teaser-tiny::after {
    content: '';
    display: table;
    clear: both;
  }
  .teaser.teaser-medium {
    margin-bottom: 35px;
  }
  .teaser.teaser-medium .selection {
    display: none;
  }
  .teaser.teaser-medium .head {
    position: relative;
    z-index: 3;
    font-size: 15px;
    line-height: 20px;
  }
  .teaser.teaser-medium .body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    max-height: 125px;
    overflow: hidden;
    z-index: 5;
    padding-top: 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.03125em;
    opacity: 0;
  }
  .teaser.teaser-medium .teaser-image {
    position: relative;
    margin-bottom: 10px;
    z-index: 0;
    opacity: 1;
  }
  .teaser.teaser-medium .teaser-image .detail-image-wrapper {
    width: 160px;
    height: 120px;
  }
  .teaser.teaser-medium .body, .teaser.teaser-medium .teaser-image, .teaser.teaser-medium::before {
    transition: opacity 270ms linear;
  }
  .teaser.teaser-medium::before {
    content: '';
    position: absolute;
    display: block;
    left: -10px;
    top: 0;
    right: -10px;
    bottom: -10px;
    opacity: 0;
    background: url("/assets/gfx/bg_dots_transparent_2.png") left top repeat #FFF;
  }
  .teaser.teaser-medium:hover .body, .teaser.teaser-medium.active .body {
    opacity: 1;
  }
  .teaser.teaser-medium:hover .teaser-image, .teaser.teaser-medium.active .teaser-image {
    opacity: 0;
  }
  .teaser.teaser-medium:hover::before, .teaser.teaser-medium.active::before {
    opacity: 1;
  }
  .teaser.teaser-large .teaser-image {
    float: left;
    display: inline-block;
    margin-right: 20px;
  }
  .teaser.teaser-large .teaser-image .detail-image-wrapper {
    width: 192px;
    height: 144px;
  }
  .teaser.teaser-large .teaser-info {
    display: table;
  }
  .teaser.teaser-large .teaser-info .head {
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: 0.02778em;
    margin-bottom: 8px;
  }
  .teaser.teaser-large .teaser-info .body {
    font-size: 14px;
    line-height: 18px;
    color: #626262;
  }
  .teaser.teaser-large .teaser-info .selection {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.04167em;
    margin-bottom: 6px;
  }
  .teaser.teaser-large::after {
    content: '';
    display: table;
    clear: both;
  }
  .teaser.teaser-text {
    padding: 4px 20px 5px;
    margin-bottom: 0;
  }
  .teaser.teaser-text::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: url("/assets/gfx/bg_dots_transparent_2.png") left top repeat #FFF;
  }
  .teaser.teaser-text:hover::before, .teaser.teaser-text.hover::before {
    opacity: 1;
  }
  .teaser.teaser-text .teaser-head {
    line-height: 18px;
  }
  .teaser.teaser-text .teaser-head .head {
    position: relative;
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
  }
  .teaser.teaser-text .teaser-head .head::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -1px;
  }
  .teaser.teaser-text .teaser-body {
    position: relative;
    max-height: 0;
    padding-top: 0;
    overflow: hidden;
  }
  .teaser.teaser-text .teaser-body::after {
    content: '';
    display: table;
    clear: both;
  }
  .teaser.teaser-text .teaser-body .body {
    font-size: 13px;
    line-height: 18px;
  }
  .teaser.teaser-text .teaser-image {
    float: left;
    display: inline-block;
    margin-right: 20px;
  }
  .teaser.teaser-text .teaser-image .detail-image-wrapper {
    width: 92px;
    height: 69px;
  }
  .teaser.teaser-text:hover, .teaser.teaser-text.hover {
    padding-bottom: 14px;
  }
  .teaser.teaser-text:hover .teaser-body, .teaser.teaser-text.hover .teaser-body {
    max-height: 94px;
    padding-top: 11px;
  }
  .teaser.teaser-text:hover .teaser-head .head::after, .teaser.teaser-text.active .teaser-head .head::after, .teaser.teaser-text.hover .teaser-head .head::after {
    width: 100%;
  }
  .teaser.teaser-small {
    margin-bottom: 58px;
  }
  .teaser.teaser-small .teaser-info {
    position: relative;
  }
  .teaser.teaser-small .teaser-image {
    float: left;
    display: inline-block;
    margin-right: 14px;
  }
  .teaser.teaser-small .teaser-image .detail-image-wrapper {
    width: 152px;
    height: 114px;
  }
  .teaser.teaser-small .selection {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.04167em;
    margin-bottom: 5px;
  }
  .teaser.teaser-small .section {
    color: #FFF;
  }
  .teaser.teaser-small .head {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.02174em;
  }
  .teaser.teaser-small .body {
    display: none;
  }
  .teaser.teaser-small .btn-remove {
    position: absolute;
    right: -10px;
    bottom: -10px;
    color: #FFF;
    opacity: 0;
    padding: 10px 10px 9px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 15px;
    z-index: 10;
    cursor: pointer;
    transition: opacity 120ms ease, background-color 180ms linear;
  }
  .teaser.teaser-small .btn-remove::after {
    margin-left: 6px;
    font-size: 9px;
  }
  .teaser.teaser-small .btn-remove:hover, .teaser.teaser-small .btn-remove.hover {
    color: #000;
    background-color: #FFF;
  }
  .teaser.teaser-small::after {
    content: '';
    display: table;
    clear: both;
  }
}

@media screen and (min-width: 1200px) and (min-width: 1200px) {
  .teaser.teaser-small::before {
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 0;
    opacity: 0;
    transition: opacity 180ms ease-out, top 120ms ease-in-out, right 120ms ease-in-out, bottom 120ms ease-in-out, left 120ms ease-in-out;
  }
  .teaser.teaser-small:hover::before, .teaser.teaser-small.hover::before {
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
    opacity: 1;
  }
  .teaser.teaser-small:hover .selection, .teaser.teaser-small.hover .selection {
    color: #FFF;
  }
  .teaser.teaser-small:hover .btn-remove, .teaser.teaser-small.hover .btn-remove {
    opacity: 1;
  }
}

.teaser-event {
  margin-bottom: 17px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.teaser-event:last-of-type {
  margin-bottom: 0;
}

.teaser-event .head, .teaser-event .teaser-date, .teaser-event .teaser-location {
  font-size: 14px;
  line-height: 18px;
  padding: 2px 0;
  font-family: "Roboto", sans-serif;
}

.teaser-event .head {
  position: relative;
  font-weight: 700;
  display: inline-block;
}

.teaser-event .head::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 1px;
}

.teaser-event .teaser-link {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
}

.teaser-event:hover .head::after, .teaser-event.active .head::after, .teaser-event.hover .head::after {
  width: 100%;
}

.partner-teaser {
  display: none;
  position: relative;
  margin: 10px;
  border-width: 2px;
  border-style: solid;
  padding: 12px;
  overflow: auto;
  zoom: 1;
  transition: opacity 220ms linear;
}

.partner-teaser .teaser-image {
  text-align: center;
}

.partner-teaser .teaser-image img {
  max-width: 100%;
  height: auto;
}

.partner-teaser .detail-image-wrapper {
  font-size: 0;
  line-height: 0;
}

.partner-teaser .teaser-info {
  max-width: 285px;
}

.partner-teaser .teaser-info .head {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin-bottom: 6px;
}

.partner-teaser .teaser-info .body {
  font-size: 12px;
  line-height: 18px;
}

.partner-teaser .teaser-link {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
}

.partner-teaser .teaser-link .partner {
  position: absolute;
  right: 20px;
  bottom: 4px;
  font-size: 12px;
  line-height: 1;
  color: #929292;
  display: block;
}

.minimized .partner-teaser {
  opacity: 0;
}

@media screen and (max-width: 1199px) {
  .partner-teaser {
    margin-top: 45px;
    padding-bottom: 20px;
  }
  .partner-teaser .teaser-image {
    margin-bottom: 12px;
  }
  .partner-teaser .teaser-info {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .partner-teaser {
    position: relative;
    background-color: #FFF;
    margin: 0 40px 80px;
    overflow: auto;
    zoom: 1;
    border-width: 4px;
    border-style: solid;
    padding: 20px;
    transition: opacity 220ms linear;
  }
  .partner-teaser .teaser-image {
    float: right;
    max-width: 142px;
  }
  .partner-teaser .teaser-image img {
    max-width: 100%;
    height: auto;
  }
  .partner-teaser .detail-image-wrapper {
    font-size: 0;
    line-height: 0;
  }
  .partner-teaser .teaser-info {
    max-width: 230px;
  }
  .partner-teaser .teaser-info .head {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .partner-teaser .teaser-info .body {
    font-size: 12px;
    line-height: 18px;
  }
  .partner-teaser .teaser-link {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
  }
  .partner-teaser .teaser-link .partner {
    position: absolute;
    right: 20px;
    bottom: 4px;
    font-size: 12px;
    line-height: 1;
    color: #929292;
    display: block;
  }
  .minimized .partner-teaser {
    opacity: 0;
  }
}

@media screen and (min-width: 1600px) {
  .partner-teaser .teaser-image {
    max-width: 300px;
  }
  .partner-teaser .teaser-info {
    max-width: 300px;
  }
  .partner-teaser .teaser-info .head {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 8px;
  }
  .partner-teaser .teaser-info .body {
    font-size: 16px;
    line-height: 23px;
  }
}

.detail-image {
  position: relative;
}

.detail-image .detail-image-wrapper {
  z-index: 1;
}

.detail-image::before, .detail-image::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 0;
  transition: opacity 200ms linear;
  /*
		width: 36px;
		height: 36px;
		border: 6px solid $color-c9;
		box-shadow: 0 0 4px -1px $color-c9, 0 0 4px -1px $color-c9 inset;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -18px;
		margin-top: -18px;
		
		opacity: 0;
		transition: opacity 160ms linear;
		*/
}

.detail-image:before {
  background-color: #EFEFEF;
  z-index: 1;
}

.detail-image::after {
  background: url("/assets/gfx/bg_dots_transparent.png") 2px 3px repeat transparent;
  z-index: 2;
}

.detail-image.lazy-image.loading::after, .detail-image.lazy-image.loading::before {
  opacity: 1;
}

.hidden-dialog {
  display: none;
}

.dialog {
  position: fixed;
  background-color: #929292;
  color: #FFF;
  padding: 16px;
}

.dialog .dialog-head {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.03125em;
  margin-bottom: 6px;
}

.dialog .dialog-body {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.03846em;
  margin-bottom: 8px;
}

.dialog .dialog-head, .dialog .dialog-body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dialog .dialog-confirm {
  display: inline-block;
  padding: 4px 16px 5px;
  background-color: #FFF;
  color: #929292;
  font-family: "Roboto Mono", monospace;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.04167em;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  .dialog .dialog-confirm {
    transition: background-color 180ms linear;
  }
  .dialog .dialog-confirm:hover {
    background-color: #EFEFEF;
  }
}

#cookie_confirmation, #cookie_enable {
  right: 0;
  bottom: 41px;
  width: 100%;
  max-width: 320px;
  padding-right: 32px;
}

#cookie_confirmation::after, #cookie_enable::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bnw-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e909";
  display: block;
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 24px;
}

@media screen and (min-width: 1200px) {
  #cookie_confirmation, #cookie_enable {
    right: 0;
    top: 72px;
    bottom: auto;
    z-index: 6000;
    max-width: 380px;
  }
  #cookie_confirmation::after, #cookie_enable::after {
    content: "\e909";
    bottom: auto;
    top: 7px;
    right: 43px;
    transform: rotate(180deg);
  }
}

[view=article] {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  position: relative;
  z-index: 10;
  color: #000;
}

[view=article] > .panel > .wrapper {
  background-color: #FFF;
}

[view=article] ::selection {
  color: white;
  background-color: black;
}

[view=article] .quellen {
  margin-top: 1em;
  font-size: 12px;
}

[view=article] .menu_print {
  margin: 50px 0 0 0;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

[view=article] .menu_print h1 {
  display: inline-block;
}

[view=article] .menu_print h1 a {
  font-size: 30px;
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  text-decoration: none;
}

[view=article] .menu_print .partner_slider_print {
  display: inline-block;
  min-height: 87px;
}

[view=article] .menu_print .partner_slider_print .partner-slide img {
  max-width: 240px;
  max-height: 87px;
  width: auto;
  height: auto;
}

[article-module] {
  padding: 0 15px;
  margin-bottom: 20px;
}

[article-module] h3 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.04167em;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 20px;
}

[article-module] b, [article-module] strong {
  font-weight: 700;
}

[article-module] i {
  font-style: italic;
}

[article-module=headline] {
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.01471em;
  margin-bottom: 18px;
}

[article-module=subheadline] {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.02778em;
  margin-bottom: 22px;
  text-transform: uppercase;
}

[article-module=textblock] h3 {
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: initial;
  font-weight: 700;
  text-transform: none;
  margin-bottom: 0;
}

[article-module=textblock] ul {
  padding-left: 20px;
  margin-bottom: 26px;
  margin-top: 14px;
}

[article-module=textblock] ul li::before {
  content: '-';
  margin-left: -18px;
  width: 14px;
  display: inline-block;
  position: absolute;
}

[article-module=textblock] p:empty {
  display: none;
}

[article-module=textblock] p + p {
  margin-top: 26px;
}

[article-module=textblock] p + h3 {
  margin-top: 26px;
}

[article-module=textblock] img {
  max-width: 100%;
  height: auto;
}

[article-module=textblock] .glossarlink {
  text-decoration: none;
  background: url(data:image/gif;base64,R0lGODlhAQAFAIAAAM3NzQAAACH5BAAAAAAALAAAAAABAAUAAAIChF0AOw==) repeat-x transparent bottom left;
}

[article-module=textblock] .glossarlink:hover {
  background: #cdcdcd;
}

[article-module=gallery], [article-module=related_article], [article-module=related_objects], [article-module=related_tips], [article-module=video_embed], [article-module=webtips], [article-module=object_information], [article-module=object_map], [article-module=architect_profile], [article-module=downloads], [article-module=event], [article-module=book] {
  padding-top: 20px;
  padding-bottom: 40px;
  background-color: #F8F8F8;
}

[article-module=gallery] figcaption, [article-module=video_embed] figcaption {
  position: relative;
  padding: 20px 0 0;
}

[article-module=gallery] .caption, [article-module=video_embed] .caption {
  font-size: 14px;
  line-height: 20px;
}

[article-module=gallery] .copyright, [article-module=video_embed] .copyright {
  font-size: 12px;
  line-height: 20px;
}

[article-module=gallery] .caption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[article-module=gallery] .index {
  font-family: "Roboto Mono", monospace;
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
  float: right;
}

[article-module=gallery] .detail-image-wrapper {
  position: relative;
  height: 240px;
  text-align: center;
}

[article-module=gallery] .detail-image-wrapper img {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  transform: translate(0, -50%);
}

[article-module=gallery] .detail-image-wrapper > .pswp-link {
  display: block;
  position: relative;
  height: 100%;
}

[article-module=gallery] .gallery-slide {
  position: relative;
}

[article-module=gallery] .gallery-link {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  z-index: 2;
  cursor: zoom-in;
}

[article-module=gallery] .gallery-link:focus {
  outline: none;
}

[article-module=video_embed] .video-wrapper {
  position: relative;
  max-width: 100%;
  padding-top: 56.25%;
}

[article-module=video_embed] .video-wrapper iframe, [article-module=video_embed] .video-wrapper .video-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

[article-module=video_embed] .video-wrapper .video-link {
  display: block;
  overflow: hidden;
  text-decoration: none;
}

[article-module=video_embed] .video-wrapper .video-link::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bnw-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\70";
  color: white;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 64px;
}

[article-module=video_embed] .video-wrapper .video-link img {
  position: relative;
  top: 50%;
  width: 100%;
  height: auto;
  transform: translate(0, -50%);
}

[article-module=book] {
  display: flex;
  flex-flow: row wrap;
}

[article-module=book] .book-cover {
  margin-right: 40px;
}

[article-module=book] .book-details {
  flex-basis: 55%;
}

[article-module=book] .book-author {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 6px;
}

[article-module=book] .book-info, [article-module=book] .book-price, [article-module=book] .book-isbn {
  font-size: 14px;
  line-height: 22px;
}

[article-module=book] .book-isbn {
  font-size: 14px;
}

[article-module=related_article] .teaser-block, [article-module=related_objects] .teaser-block, [article-module=related_tips] .teaser-block {
  margin-left: -20px;
  margin-right: -20px;
}

[article-module=object_map] .map {
  display: inline-block;
  position: relative;
  min-height: 112px;
  border: 1px solid #c3c3c3;
}

[article-module=object_map] .map a {
  display: block;
  cursor: pointer;
  position: relative;
  /*
			&::after {
				@include icon-styles();
				content: "\e90b";
				display: block;
				position: absolute;
				font-size: 42px;
				line-height: 1;
				height: 42px;
				left: 50%;
				bottom: 50%;
				margin-left: -21px;
			}
            */
}

[article-module=object_map] .map img {
  height: auto;
  display: block;
}

[article-module=object_information] .infos {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

[article-module=object_information] .infos h3 {
  font-size: 14px;
  line-height: 22px;
  position: relative;
}

[article-module=object_information] .infos .iconafter-architect_profile {
  font-size: 24px;
  position: absolute;
  top: -3px;
  margin-left: 6px;
}

[article-module=object_information] [article-module=object_map] {
  padding: 0;
  margin: 20px 12px 0 0;
}

[article-module=object_information] [article-module=architect_profile] {
  padding: 0;
  margin: 20px 0 0 0;
}

[article-module=share] .share-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

[article-module=share] .social-links {
  min-width: 180px;
  flex: 2;
  align-self: flex-end;
  position: relative;
}

[article-module=share] .share-artikel-actions {
  flex: 1;
  align-self: flex-end;
}

[article-module=share] p {
  font-size: 14px;
  line-height: 32px;
}

[article-module=share] .share-link {
  position: relative;
  display: block;
  line-height: 32px;
  text-decoration: none;
  vertical-align: middle;
}

[article-module=share] .share-link::before {
  font-size: 28px;
  color: #313131;
  display: inline-block;
  position: relative;
  width: 37px;
  vertical-align: middle;
}

[article-module=share] .share-link.icon-social_linkedin:before {
  font-size: 22px;
}

[article-module=share] .share-link span {
  font-size: 14px;
}

[article-module=share] .newsletter-share-link img {
  display: inline-block;
  vertical-align: middle;
  padding: 0 6px;
}

[article-module=share] .share-artikel-actions a {
  width: 72px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

[article-module=share] .share-artikel-actions a:not(:hover) {
  color: #313131;
}

[article-module=share] .share-artikel-actions a span {
  display: block;
  margin-bottom: 6px;
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.03846em;
  text-align: center;
}

[article-module=share] .share-artikel-actions a::after {
  display: block;
  text-align: center;
  font-size: 30px;
  line-height: 30px;
}

[article-module=share] .share-artikel-actions a.print-link::after {
  font-size: 27px;
}

[article-module=share] .share-artikel-actions a + a {
  margin-left: 6px;
}

[article-module=share] .share-artikel-actions a.watchlist-trigger.checked, [article-module=share] .share-artikel-actions a.watchlist-trigger.checked:hover {
  color: #c3c3c3;
}

[article-module=share] .share-artikel-actions a.newsletter-link .newsletter-icon {
  margin: 12px auto 0 auto;
  display: block;
}

[article-module=event] {
  display: flex;
  flex-flow: row wrap;
}

@media screen and (min-width: 1200px) {
  [article-module=event] {
    flex-flow: row nowrap;
  }
}

[article-module=event] .event-cover {
  margin-right: 40px;
  margin-bottom: 15px;
  font-size: 0;
  line-height: 0;
}

[article-module=event] .event-cover .detail-image {
  width: 220px;
}

[article-module=event] .event-cover img {
  max-width: 100%;
  width: auto;
  max-height: 165px;
  height: auto;
}

[article-module=event] .event-cover .copyright {
  margin-top: 6px;
  font-size: 11px;
  line-height: initial;
}

[article-module=event] dl {
  margin-top: -3px;
  flex-basis: 55%;
}

[article-module=event] .detail-image a, [article-module=book] .detail-image a {
  cursor: default;
}

[article-module=partner-contact] {
  display: none;
}

[article-module=architect_profile] h3 {
  margin-bottom: 3px;
}

[article-module=architect_profile] .external-link-list li {
  padding-bottom: 0;
}

@media screen and (min-width: 720px) {
  [article-module=headline] {
    font-size: 32px;
    line-height: 41px;
  }
  [article-module=object_information] [article-module=architect_profile] {
    flex-basis: 45%;
  }
  [article-module=event] .event-cover {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1199px) {
  [view=article] {
    overflow-x: hidden;
    background-color: #FFF;
  }
  [view=article] > .panel {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }
  [view=article] .article-back-to-top {
    padding: 0;
    width: 100%;
  }
  [view=article] .article-back-to-top a {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    width: 100%;
    line-height: 31px;
    text-decoration: none;
  }
  [view=article] .article-back-to-top:last-child {
    padding: 0 0 42px;
  }
  [view=article] [article-module=share] .head {
    display: block;
    margin-bottom: 8px;
  }
  [view=article] [article-module=share] .share-link {
    background-color: #EFEFEF;
    padding: 2px;
  }
  [view=article] [article-module=share] .share-link:not(:last-of-type) {
    margin-bottom: 20px;
  }
  [view=article] [article-module=share] .share-link::before {
    text-align: center;
    margin-right: 8px;
  }
  [view=article] [article-module=share] .share-artikel-actions a {
    display: block;
    background-color: #EFEFEF;
    margin-left: auto;
    padding: 4px;
  }
  [view=article] [article-module=share] .share-artikel-actions a:not(:last-of-type) {
    margin-top: 8px;
  }
  [view=article] [article-module=share] .share-artikel-actions a + a {
    margin-left: auto;
  }
}

@media screen and (min-width: 1200px) {
  [view=article] > .panel {
    position: absolute;
    width: 522px;
  }
  [view=article] > .panel > .wrapper {
    padding: 23px 0 15px;
    box-shadow: 0 0 6px -3px #000;
    margin-bottom: 80px;
  }
  [view=article] .article-back-to-top {
    display: none;
  }
  [view=topic] [view=article] > .panel {
    min-height: 100%;
    top: 101px;
    right: 30px;
    z-index: 10;
    transition: background-color 240ms linear;
  }
  [view=topic] [view=article] > .panel.minimized {
    overflow-x: hidden;
    background-color: #FFF;
  }
  [view=topic] [view=article] .placeholder {
    position: fixed;
    width: 740px;
    max-width: 78vh;
    height: 150px;
    line-height: 150px;
    transform-origin: 0 50%;
    left: 100%;
    top: 50%;
    transform: rotate(-90deg) translate(-50%, -50%);
  }
  [view=topic] [view=article] .placeholder h2 {
    display: inline-block;
    font-size: 38px;
    line-height: 50px;
    margin: 0;
    vertical-align: middle;
  }
  [view=glossary] [view=article] {
    position: relative;
    float: right;
    top: 0;
    width: 522px;
  }
  [view=glossary] [view=article].animate-out {
    position: absolute;
    right: 0;
  }
  [view=glossary] [view=article] > .panel {
    position: relative;
    right: 0;
    top: 0;
  }
  [view=glossary] [view=article] > .panel > .wrapper {
    margin-bottom: 75px;
  }
  [article-module] {
    padding: 0 30px;
    margin-bottom: 20px;
  }
  [article-module] h3 {
    font-size: 22px;
    letter-spacing: 0.09091em;
  }
  [article-module=textblock] h3 {
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: initial;
    font-weight: 700;
    text-transform: none;
    margin-bottom: 0;
  }
  [article-module=share] .social-links {
    position: relative;
    top: -5px;
  }
  [article-module=share] .share-link {
    display: inline-block;
    width: 32px;
    text-align: center;
  }
  [article-module=share] .share-link::before {
    vertical-align: baseline;
  }
  [article-module=share] .share-link span {
    position: absolute;
    bottom: 175%;
    left: 50%;
    padding: 4px 6px 3px;
    white-space: nowrap;
    background-color: #313131;
    color: white;
    display: block;
    transform: translate(-50%, 0);
    transition: bottom 180ms ease;
  }
  [article-module=share] .share-link:hover span {
    bottom: 125%;
  }
  [article-module=share] .share-link span {
    font-size: 12px;
    line-height: 19px;
  }
  [article-module=share] .share-link:not(:hover) span {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
  }
  [article-module=share] .newsletter-share-link {
    display: none;
  }
  [article-module=share] .share-artikel-actions {
    display: flex;
    justify-content: flex-end;
  }
  [article-module=share] .share-artikel-actions a {
    float: left;
  }
  [article-module=share] .share-artikel-actions a.watchlist-trigger.checked, [article-module=share] .share-artikel-actions a.watchlist-trigger.checked:hover {
    color: #EFEFEF;
  }
  [article-module=headline] {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.03125em;
    margin-bottom: 10px;
  }
  [article-module=headline]:not(.small) {
    font-size: 38px;
    line-height: 50px;
    letter-spacing: 0.02632em;
  }
  [view=article] {
    font-size: 15px;
    line-height: 24px;
  }
  [article-module=subheadline] {
    margin-bottom: 13px;
    font-size: 16px;
    line-height: 22px;
  }
  [article-module=gallery], [article-module=video_embed], [article-module=object_information], [article-module=object_map], [article-module=downloads], [article-module=event], [article-module=book] {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  [article-module=event] {
    padding-bottom: 30px;
  }
  [article-module=related_article], [article-module=related_objects], [article-module=related_tips] {
    padding-top: 30px;
    padding-bottom: 10px;
  }
  [article-module=architect_profile], [article-module=webtips], [article-module=downloads] {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  [article-module=architect_profile] a, [article-module=webtips] a, [article-module=downloads] a {
    position: relative;
  }
  [article-module=architect_profile] a::before, [article-module=webtips] a::before, [article-module=downloads] a::before {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -2px;
    z-index: 0;
    transition: width 180ms ease-out;
  }
  [article-module=architect_profile] a.active::before, [article-module=architect_profile] a:hover::before, [article-module=architect_profile] a:focus::before, [article-module=webtips] a.active::before, [article-module=webtips] a:hover::before, [article-module=webtips] a:focus::before, [article-module=downloads] a.active::before, [article-module=downloads] a:hover::before, [article-module=downloads] a:focus::before {
    width: 100%;
  }
  [article-module=gallery], [article-module=video_embed] {
    padding-bottom: 17px;
  }
  [article-module=gallery] figcaption, [article-module=video_embed] figcaption {
    padding: 17px 110px 0 50px;
  }
  [article-module=gallery] {
    padding-left: 0;
    padding-right: 0;
  }
  [article-module=gallery] h3 {
    padding: 0 40px;
  }
  [article-module=gallery] .index {
    position: absolute;
    right: 15px;
    top: 36px;
  }
  [article-module=gallery] .detail-image-wrapper {
    margin: 0 40px;
    height: 300px;
  }
  [article-module=book] {
    position: relative;
    padding-bottom: 30px;
  }
  [article-module=book]::after {
    content: "";
    display: table;
    clear: both;
  }
  [article-module=video_embed] .video-wrapper .video-link::after {
    font-size: 96px;
  }
}

@media screen and (min-width: 1600px) {
  [article-module] {
    margin-bottom: 30px;
  }
  [article-module] h3 {
    font-size: 24px;
    letter-spacing: 0.08333em;
  }
  [article-module=textblock] h3 {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: initial;
    font-weight: 700;
    text-transform: none;
    margin-bottom: 0;
  }
  [article-module=headline] {
    margin-bottom: 20px;
  }
  [view=topic] [article-module=headline]:not(.small) {
    font-size: 40px;
    line-height: 48px;
  }
  [article-module=subheadline] {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 13px;
  }
  [view=topic] [view=article]:not(.glossary-article) [view=article] {
    font-size: 16px;
    line-height: 26px;
  }
  [view=topic] [view=article]:not(.glossary-article) > .panel {
    right: 60px;
    width: 740px;
  }
  [view=topic] [view=article]:not(.glossary-article) .placeholder [article-module=headline] {
    font-size: 38px;
    line-height: 50px;
  }
  [view=topic] [view=article]:not(.glossary-article) [article-module=gallery] .detail-image-wrapper {
    height: 495px;
  }
  [view=topic] [view=article]:not(.glossary-article) [article-module=object_map] .map {
    min-height: 140px;
  }
}

@media screen and (min-width: 2200px) {
  [view=topic] [view=article]:not(.glossary-article) > .panel {
    right: 45%;
    margin-right: -940px;
  }
}

body {
  background-color: #FFF;
  width: 700px;
  /*
	.article-url {
		margin-bottom: 20px;
		padding-left: 20px;
		width: $article-width-print;
		font-family: $font-first;
		font-size: 14px;

		a {
			text-decoration: none;
		}
	}
    */
}

body #menu_print {
  width: 700px;
  margin: 50px 0 0 0;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body #menu_print .icon-logo {
  display: inline-block;
}

body #menu_print .icon-logo a {
  font-size: 30px;
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  text-decoration: none;
}

body #menu_print #partner_slider_print {
  display: inline-block;
  min-height: 87px;
}

body #menu_print #partner_slider_print .partner-slide img {
  max-width: 240px;
  max-height: 87px;
  width: auto;
  height: auto;
}

body .topic {
  margin: -16px 0 20px 0;
  padding-left: 20px;
  width: 700px;
  font-family: "Roboto Mono", monospace;
  font-weight: normal;
  font-size: 22px;
}

body hr {
  width: 700px;
  margin: 0;
  border: 0 none transparent;
  border-bottom: 2px dashed black;
}

[view=article] {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  position: relative;
  z-index: 10;
  color: #000;
}

[view=article] > .panel {
  position: absolute;
  width: 700px;
}

[view=article] > .panel > .wrapper {
  padding: 0;
  background-color: #FFF;
  box-shadow: none;
  margin-bottom: 0;
  margin-top: 25px;
}

[view=article] ::selection {
  color: white;
  background-color: black;
}

[view=article] .external-link-list a {
  font-family: "Roboto", sans-serif;
  letter-spacing: normal;
}

[article-module] {
  padding-left: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px dashed black;
}

[article-module]:last-of-type {
  border-bottom: 0 none transparent;
}

[article-module] h3 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.08333em;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 20px;
}

[article-module] b, [article-module] strong {
  font-weight: 700;
}

[article-module] i {
  font-style: italic;
}

[article-module] .teaser {
  display: flex;
  align-items: flex-start;
}

[article-module] .teaser .teaser-image {
  float: none;
  margin-right: 14px;
}

[article-module] .teaser .teaser-link {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  overflow-wrap: break-word;
}

[article-module] .glossarlink {
  text-decoration: none;
}

[article-module=headline] {
  font-weight: 300;
  padding-bottom: 0;
  margin-bottom: 25px;
  font-size: 44px;
  line-height: 50px;
  letter-spacing: 0.02632em;
  border-bottom: 0 none transparent;
}

[article-module=subheadline] {
  margin-bottom: 30px;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.02778em;
  text-transform: uppercase;
  border-bottom: 0 none transparent;
}

[article-module=textblock] {
  font-size: 14px;
  line-height: 23px;
}

[article-module=textblock] ul {
  padding-left: 20px;
  margin-top: 14px;
  margin-bottom: 25px;
}

[article-module=textblock] ul li::before {
  content: '-';
  margin-left: -18px;
  width: 14px;
  display: inline-block;
  position: absolute;
}

[article-module=textblock] > p {
  margin-bottom: 25px;
}

[article-module=textblock] > *:last-of-type {
  margin-bottom: 0;
}

[article-module=object_map] {
  display: none;
}

[article-module=gallery], [article-module=related_article], [article-module=related_objects], [article-module=related_tips], [article-module=webtips], [article-module=object_information], [article-module=object_map], [article-module=architect_profile], [article-module=downloads], [article-module=event], [article-module=book] {
  padding-left: 20px;
  padding-top: 0;
  background-color: #FFF;
}

[article-module=gallery] figcaption {
  display: none;
}

[article-module=gallery] .caption {
  font-size: 11px;
  line-height: 14px;
  height: auto;
  width: auto;
  clip: auto;
  position: static;
}

[article-module=gallery] .copyright {
  font-size: 9px;
  line-height: 14px;
}

[article-module=gallery] {
  padding-bottom: 25px;
  margin-bottom: 25px;
}

[article-module=gallery] .article-print-image-and-caption {
  width: 233px;
  overflow: hidden;
  margin-bottom: 15px;
}

[article-module=gallery] .article-print-image-and-caption .article-print-image {
  height: 160px;
}

[article-module=architect_profile] h3::after {
  margin-left: 0.175em;
}

[article-module=book] {
  display: flex;
  flex-flow: row wrap;
}

[article-module=book] .book-details {
  flex-basis: 55%;
}

[article-module=book] .book-cover {
  margin-right: 40px;
}

[article-module=book] .book-author {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 6px;
}

[article-module=book] .book-info, [article-module=book] .book-price, [article-module=book] .book-isbn {
  font-size: 14px;
  line-height: 22px;
}

[article-module=book] .book-isbn {
  font-size: 12px;
}

[article-module=related_article], [article-module=related_objects], [article-module=related_tips] {
  margin-bottom: 25px;
  padding-bottom: 0;
}

[article-module=related_article] .teaser-block, [article-module=related_objects] .teaser-block, [article-module=related_tips] .teaser-block {
  margin-left: -20px;
  margin-right: -20px;
}

[article-module=related_article] .teaser .teaser-info p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
}

[article-module=related_article] .teaser .teaser-info p.selection {
  font-size: 12px;
}

[article-module=related_article] .teaser .teaser-link {
  display: inline;
  position: static;
  width: auto;
  height: auto;
  text-decoration: none;
}

[article-module=object_information] {
  font-size: 14px;
}

[article-module=object_map] .map {
  display: inline-block;
  position: relative;
  min-height: 112px;
  margin-top: 44px;
  border: 1px solid #c3c3c3;
}

[article-module=object_map] .map a {
  display: block;
  cursor: pointer;
  position: relative;
  /*
			&::after {
				@include icon-styles();
				content: "\e90b";
				display: block;
				position: absolute;
				font-size: 42px;
				line-height: 1;
				height: 42px;
				left: 50%;
				bottom: 50%;
				margin-left: -21px;
			}
            */
}

[article-module=object_map] .map img {
  height: auto;
  display: block;
}

[article-module=object_map] .address {
  font-size: 14px;
  line-height: 22px;
}

[article-module=object_information] .infos {
  display: flex;
  flex-flow: column nowrap;
}

[article-module=object_information] [article-module=object_map] {
  padding: 0;
  margin: 20px 0 0 0;
  border-bottom: none;
}

[article-module=object_information] [article-module=object_map] .map {
  margin: 0;
}

[article-module=object_information] [article-module=architect_profile] {
  padding: 0;
  margin: 20px 0 0 0;
}

[article-module=object_information] [article-module=architect_profile] .external-link-list a:after {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  line-height: 26px;
  content: " -> " attr(href);
}

[article-module=event] {
  display: block;
}

[article-module=event] .event-cover {
  margin-bottom: 15px;
  font-size: 0;
  line-height: 0;
}

[article-module=event] .event-cover img {
  max-width: 100%;
  height: auto;
}

[article-module=event] .detail-image a, [article-module=book] .detail-image a {
  cursor: default;
}

[article-module=architect_profile] {
  padding-bottom: 20px;
}

[article-module=partner-contact] {
  font-size: 14px;
  line-height: 23px;
  padding-right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

[article-module=partner-contact] .partner-contact-text {
  max-width: 435px;
}

[article-module=partner-contact] .partner-contact-slide img {
  max-width: 240px;
  max-height: 87px;
  width: auto;
  height: auto;
}

@media print {
  @page {
    size: auto;
    margin-left: 25mm;
    margin-right: 25mm;
  }
  [article-module=related_objects], [article-module=webtips], [article-module=related_tips], [article-module=object_information], [article-module=object_map], [article-module=architect_profile], [article-module=downloads], [article-module=event], [article-module=book] {
    page-break-inside: avoid;
  }
  [article-module=gallery] .article-print-image-and-caption {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  [article-module=event] {
    display: flex;
    flex-wrap: wrap;
  }
  [article-module=event] h3 {
    width: 100%;
  }
  [article-module=event] .event-cover {
    margin-right: 40px;
  }
  [article-module] .row.expand {
    display: flex;
    justify-content: space-between;
  }
}

.external-link-list li, .download-link-list li {
  padding-top: 3px;
  padding-bottom: 4px;
}

.external-link-list a, .download-link-list a {
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.07143em;
  line-height: 26px;
  text-decoration: none;
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
}

.external-link-list a::after, .download-link-list a::after {
  content: "\2192";
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bnw-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin-left: 0.5em;
  display: inline-block;
  top: 1px;
}

.download-link-list a::after {
  content: "\2193";
}

[view=taxonomy-menu], [view=topic-teaser], .topic-background, #eye_catcher, #menu, [view="topic"]::before, .partner-teaser,
.overscroll-indicator, .article-back-to-top, #topic_dropdown, #cookie_confirmation, .article-next {
  display: none !important;
}

[view=article] {
  width: 100%;
}

[article-module=gallery] .slick-arrow {
  display: none !important;
}

[article-module=share] {
  display: none !important;
}

[view=glossary] {
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
}

[view=glossary] .page-head, [view=glossary] .alphabetic-selector, [view=glossary] .close-page, [view=glossary] .glossary-list, [view=glossary] .btn-close-article, [view=glossary] [article-module=topic] {
  display: none !important;
}

[view=glossary] .text-page {
  background-color: #fff;
}

[view=glossary] [article-module=headline] {
  color: #000;
}
