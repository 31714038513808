[view=taxonomy-menu], [view=topic-teaser], .topic-background, #eye_catcher, #menu, [view="topic"]::before, .partner-teaser,
.overscroll-indicator, .article-back-to-top, #topic_dropdown, #cookie_confirmation, .article-next {
  display: none !important;
}

[view=article] {
  width: 100%;
}

[article-module=gallery] {
  .slick-arrow {
    display: none !important;
  }
}

[article-module=share] {
  display: none !important;
}

[view=glossary] {
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    
    .page-head, .alphabetic-selector, .close-page, .glossary-list, .btn-close-article, [article-module=topic] {
        display: none !important;
    }
    .text-page {
        background-color: #fff;
    }
    [article-module=headline] {
        color: #000;
    }
}