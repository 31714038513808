$article-width-print: 700px !default;

body {
	background-color: $color-c2;
	width: $article-width-print;
    
	#menu_print {
		width: $article-width-print;
		margin: 50px 0 0 0;
		padding-left: 20px;
		display: flex;
    	justify-content: space-between;
    	align-items: center;

		.icon-logo {
			display: inline-block;

			a {
				font-size: 30px;
				font-family: $font-second;
				font-weight: 500;
				text-decoration: none;
			}
		}

		#partner_slider_print {
			display: inline-block;
            min-height: 87px;

			.partner-slide {
				
				img {
					max-width: 240px;
					max-height: 87px;
					width: auto;
					height: auto;
				}
				
			}
		}
	}

    /*
	.article-url {
		margin-bottom: 20px;
		padding-left: 20px;
		width: $article-width-print;
		font-family: $font-first;
		font-size: 14px;

		a {
			text-decoration: none;
		}
	}
    */

    .topic {
        margin: -16px 0 20px 0;
        padding-left: 20px;
        width: $article-width-print;
        font-family: $font-second;
        font-weight: normal;
        font-size: 22px;
    }

	hr {
		width: $article-width-print;
		margin: 0;
		border: 0 none transparent;
    	border-bottom: 2px dashed black;
	}
}

[view=article] {
	font-family: $font-first;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	position: relative;
	z-index: 10;
	color: $color-c1;

	& > .panel {
		position: absolute;
		width: $article-width-print;

		& > .wrapper {
			padding: 0;
			background-color: $color-c2;
			box-shadow: none;
			margin-bottom: 0;
			margin-top: 25px;
		}
	}

	& ::selection {
		color: white;
		background-color: black;
	}

	.external-link-list a {
		font-family: $font-first;
		letter-spacing: normal;
	}
}

[article-module] {
	padding-left: 20px;
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 2px dashed black;

	&:last-of-type {
		border-bottom: 0 none transparent;
	}

	h3 {
		font-size: 24px;
		font-weight: 700;
		letter-spacing: 2/24 * 1em;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	b, strong {
		font-weight: 700;
	}

	i {
		font-style: italic;
	}

	.teaser {
		display: flex;
		align-items: flex-start;

		.teaser-image{
			float: none;
			margin-right: 14px;
		}

		.teaser-link {
			font-family: $font-first;
			font-weight: 500;
			font-size: 12px;
			overflow-wrap: break-word;
		}
	}

	.glossarlink {
		text-decoration: none;
	}
}

[article-module=headline] {
		font-weight: 300;
		padding-bottom: 0;
		margin-bottom: 25px;
		font-size: 44px;
		line-height: 50px;
		letter-spacing: 1/38 * 1em;
		border-bottom: 0 none transparent;
}

[article-module=subheadline] {
	margin-bottom: 30px;
	padding-bottom: 0;
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	letter-spacing: 1/36 * 1em;
	text-transform: uppercase;
	border-bottom: 0 none transparent;
}

[article-module=textblock] {
	font-size: 14px;
	line-height: 23px;

	ul {
		padding-left: 20px;
		margin-top: 14px;
		margin-bottom: 25px;

		li {
			&::before {
                content: '-';
                margin-left: -18px;
                width: 14px;
                display: inline-block;
                position: absolute;
			}
		}
	}
	> p {
		margin-bottom: 25px;
	}
	> *:last-of-type {
		margin-bottom: 0;
	}
}

[article-module=object_map] {
    display: none;
}

[article-module=gallery], [article-module=related_article], [article-module=related_objects], [article-module=related_tips], [article-module=webtips], [article-module=object_information], [article-module=object_map], [article-module=architect_profile], [article-module=downloads], [article-module=event], [article-module=book] {
	padding-left: 20px;
	padding-top: 0;
	background-color: $color-c2;
}

[article-module=gallery] {
	figcaption {
		//position: relative;
		//padding: 20px 0 0;
        display: none;
	}
	.caption {
		font-size: 11px;
		line-height: 14px;
		height: auto;
    	width: auto;
    	clip: auto;
    	position: static;
	}
	.copyright {
		font-size: 9px;
		line-height: 14px;
	}
}

[article-module=gallery] {
	padding-bottom: 25px;
	margin-bottom: 25px;

	.article-print-image-and-caption {
		width: 233px;
		overflow: hidden;
		margin-bottom: 15px;

		.article-print-image {
			height: 160px;
		}
	}
}

[article-module=architect_profile] {
	h3 {
		&::after {
			margin-left: 0.175em;
		}
	}
}



[article-module=book] {
    display: flex;
    flex-flow: row wrap;

    .book-details {
        flex-basis: 55%;
    }

	.book-cover {
		margin-right: 40px;
	}
	
	.book-author {
		font-weight: 700;
		font-size: 16px;
		line-height: 1;
		margin-bottom: 6px;
	}

	.book-info, .book-price, .book-isbn {
		font-size: 14px;
		line-height: 22px;
	}

    .book-isbn {
        font-size: 12px;
    }
}


[article-module=related_article], [article-module=related_objects], [article-module=related_tips] {
	margin-bottom: 25px;
	padding-bottom: 0;

	.teaser-block {
		margin-left: -20px;
		margin-right: -20px;
	}
}

[article-module=related_article] {

	.teaser {
		.teaser-info p {
			font-size: 14px;
			font-family: $font-first;
			line-height: 1.2;
			&.selection {
				font-size: 12px;
			}
		}

		.teaser-link {
			display: inline;
			position: static;
			width: auto;
			height: auto;
			text-decoration: none;
		}
	}
}

[article-module=object_information] {
	font-size: 14px;
}


[article-module=object_map] {
	.map {
		display: inline-block;
		position: relative;
		min-height: 112px;
		margin-top: 44px;
		border: 1px solid $color-c5;

		a {
			display: block;
			cursor: pointer;
			position: relative;

            /*
			&::after {
				@include icon-styles();
				content: "\e90b";
				display: block;
				position: absolute;
				font-size: 42px;
				line-height: 1;
				height: 42px;
				left: 50%;
				bottom: 50%;
				margin-left: -21px;
			}
            */
		}

		img {
			height: auto;
			display: block;
		}
	}
	.address {
		font-size: 14px;
		line-height: 22px;
	}
}

[article-module=object_information] {
    .infos {
        display: flex;
        flex-flow: column nowrap;
    }

    [article-module=object_map] {
        padding: 0;
        margin: 20px 0 0 0;
        border-bottom: none;

        .map {
            margin: 0;
        }
    }

    [article-module=architect_profile] {
        padding: 0;
        margin: 20px 0 0 0;

        .external-link-list a:after {
            font-family: $font-first !important;
            font-size: 14px;
            line-height: 26px;
            content: " -> " attr(href);
        }
    }
}

[article-module=event] {
	display: block;

    .event-cover {
		margin-bottom: 15px;
		font-size: 0;
		line-height: 0;

		img {
			max-width: 100%;
			height: auto;
		}
	}
}

[article-module=event], [article-module=book] {
	.detail-image a {
		cursor: default;
	}
}

[article-module=architect_profile] {
	padding-bottom: 20px;
}


[article-module=partner-contact] {
    font-size: 14px;
   	line-height: 23px;
    padding-right: 0;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    .partner-contact-text {
        //display: inline-block;
        max-width: 435px;
    }
    .partner-contact-slide {
        //display: inline-block;

        img {
            max-width: 240px;
            max-height: 87px;
            width: auto;
            height: auto;
        }
    }
}

@media print {

	@page { 
	    size: auto;
	    margin-left: 25mm;
	    margin-right: 25mm;  
	} 

	[article-module=related_objects], [article-module=webtips], [article-module=related_tips], [article-module=object_information], [article-module=object_map], [article-module=architect_profile], [article-module=downloads], [article-module=event], [article-module=book] {
		page-break-inside: avoid;
	}

	[article-module=gallery] {
		.article-print-image-and-caption {
			page-break-before: auto;
    		page-break-after: auto; 
    		page-break-inside: avoid;
		}
	}

	[article-module=event] {
		display: flex;
		flex-wrap: wrap;

		h3 {
			width: 100%;
		}

		.event-cover {
			margin-right: 40px;
		}
	}

	[article-module] {
		.row.expand {
			display: flex;
			justify-content: space-between;
		}
	}
}

